import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import FloatingSection from "../../components/floatingSection";


function HistorySection() {
  const data = useStaticQuery(graphql`
    query HistorySectionQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "know-us"}}) {
        frontmatter {
          historySection {
            founderSectionParas
            introSectionParas
            foundersSectionImage1
            foundersSectionImage2
          }
        }
      }
    }
  `)
  const {
    introSectionParas, founderSectionParas, foundersSectionImage1, foundersSectionImage2,
  } = data.markdownRemark.frontmatter.historySection;

  return (
    <div id="history">
      <div className="bg-[url('../img/backgrounds/background5.jpg')] bg-[center_top_-30rem] bg-no-repeat">
        <div className="md:w-2/3 xl:w-1/2 p-20 mx-auto">
          <FloatingSection>
            {introSectionParas.map((para, index) => (
              <p key={index} className="text text-justify pb-10">
                {para}
              </p>
            ))}
          </FloatingSection>
        </div>
      </div>

      <div className="md:flex flex-row">
        <div className="flex-1 relative min-h-[24rem] max-h-[30rem]">
          <div className="absolute w-3/4 bg-yellow-600 top-20 bottom-20"/>
          <img src={foundersSectionImage1} alt="Founders1" className="absolute w-1/2 md:w-2/3 xl:w-1/2"/>
          <img src={foundersSectionImage2} alt="Founders2"
               className="absolute w-1/2 md:w-2/3 xl:w-1/2 bottom-0 right-10"/>
        </div>
        <div className="flex-1 p-20">
          <p className="text-center text-2xl -mt-10 mb-10">Our Founders</p>
          {founderSectionParas.map((para, index) => (
            <p key={index} className="text-justify pb-10">
              {para}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HistorySection;
