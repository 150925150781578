import React from "react";
import {graphql, useStaticQuery} from "gatsby";

function KnowUsVideo() {
  const data = useStaticQuery(graphql`
    query KnowUsVideoQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "know-us"}}) {
        frontmatter {
          knowUsYoutubeEmbedURL
        }
      }
    }
  `);
  const {knowUsYoutubeEmbedURL} = data.markdownRemark.frontmatter;

  React.useEffect(() => {
    const videoIFrameEl = document.getElementsByClassName('yt_player_iframe')[0];
    if (!videoIFrameEl) return;

    function pauseVideo() {
      videoIFrameEl.contentWindow.postMessage('{"event":"command","func":"pauseVideo"}', '*')
    }

    function playVideo() {
      videoIFrameEl.contentWindow.postMessage('{"event":"command","func":"playVideo"}', '*')
    }

    function handleScroll() {
      const rect = videoIFrameEl.getBoundingClientRect();
      const mid = ((rect.bottom - (rect.bottom - rect.top) / 2) / window.innerHeight) - 0.5;
      if (Math.abs(mid) <= 1) playVideo();
      else pauseVideo();
    }

    handleScroll();
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <iframe className="yt_player_iframe pointer-events-none" width="100%" height="600"
            src={`${knowUsYoutubeEmbedURL}?autoplay=0&mute=0&enablejsapi=1&controls=0&disablekb=1`}
            title="Raphael Ryder Cheshire International Dehradun informational video" frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen/>
  );
}

export default KnowUsVideo;
