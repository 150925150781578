import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import EmphasisTextUnderlined from "../../components/emphasisTextUnderline";
import placeholderImage from '../../img/components/navbar/raphael-logo.png';

function RegistrationDetails() {
  const data = useStaticQuery(graphql`
    query RegistrationDetailsQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "stay-informed"}}) {
        frontmatter {
          registrationDetails {
            title
            link
            information
            logo
          }
        }
      }
    }
  `);
  const {registrationDetails} = data.markdownRemark.frontmatter;

  return (
    <div id="registrationDetails" className="mx-20 mt-10">
      <div className="pt-10 pb-5">
        <EmphasisTextUnderlined strokeType="strokeSmallBlue" containerClassName="" className="w-36"/>
        <p className="pt-10 text-3xl font-bold text-blue-400">Our Registrations</p>
        <p className="my-5">Click to view the details of any of our registrations below</p>
      </div>

      <div className="">
        {registrationDetails.map(({title, link, information, logo}, index) => {
          const infoSection = !!information ? <span className="text-sm">&nbsp;({information})</span> : null;
          const details = !!link ? (
            <>
            <a href={link} className="hover:text-blue-400 text-blue-800">{title}</a>
            {infoSection}
            </>
          ) : (
            <span>{title}{infoSection}</span>
          );
          return (
            <div key={index} className="text-xl mb-5 flex flex-row items-center flex-wrap">
              <img src={logo || placeholderImage} className="w-20 h-20 mr-5 object-contain"/>
              {details}
            </div>
          );
        })}
      </div>

    </div>
  )
}

export default RegistrationDetails;
