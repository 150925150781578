import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import HelpMethodsSection from "../../components/helpMethodsSection";
import ImageCaptionSection from "../../components/imageCaptionSection";
import HistorySection from "./historySection";
import GoverningCouncilSection from "./governingCouncilSection";
import KnowUsVideo from "./knowUsVideo";
import RegistrationDetails from "./registrationDetails";

const KnowUsPage = (props) => {

  const data = useStaticQuery(graphql`
    query KnowUsPage {
      markdownRemark(frontmatter: {templateKey: {eq: "know-us"}}) {
        frontmatter {
          imageCaptionSection {
            subTitle
            title
            imageCaptionBackground
          }
        }
      }
    }
  `);
  const {title, subTitle, imageCaptionBackground} = data.markdownRemark.frontmatter.imageCaptionSection;

  return (
    <Layout activeLink="/know-us" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground}/>
      <KnowUsVideo/>
      <HistorySection />
      <GoverningCouncilSection/>
      <RegistrationDetails/>
      <HelpMethodsSection/>
    </Layout>
  );
};

KnowUsPage.propTypes = {};

export default KnowUsPage;
