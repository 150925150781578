import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import ImageGallery from "react-image-gallery";

function GoverningCouncilSection() {
  const data = useStaticQuery(graphql`
    query GoverningCouncilSectionQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "know-us"}}) {
        frontmatter {
          governingCouncilSection {
            introSectionParas
            governingCouncil {
              gender
              member
              name
              occupation
            }
            gallery {
              image
              caption
            }
          }
        }
      }
    }
  `)
  const {
    introSectionParas, governingCouncil, gallery,
  } = data.markdownRemark.frontmatter.governingCouncilSection;
  
  const galleryImages = (gallery || []).map(({image, caption}) => ({
    original: image,
    thumbnail: image,
    description: caption,
  }));
  
  return (
    <div id="governing-council">
      <div className="w-11/12 mx-auto bg-gray-100">
        <div className="w-11/12 mx-auto">
          <ImageGallery items={galleryImages} showThumbnails={false}/>
        </div>

        <p className="text-center text-2xl mb-10 mt-10 pl-5 pr-5">Governing Council</p>

        {introSectionParas.map((para, index) => (
          <p key={index} className="md:w-2/3 p-5 pb-0 mx-auto text-justify">{para}</p>
        ))}

        <div className="overflow-y-hidden">
          <table className="min-x-[600px] mx-auto m-10">
            <thead>
            <tr>
              <th colSpan={1} className="text-left p-2"/>
              <th colSpan={3} className="text-left p-2">Name</th>
              <th colSpan={1} className="text-center p-2">Gender</th>
              <th colSpan={3} className="text-center p-2">Occupation</th>
              <th colSpan={2} className="text-center p-2">Role</th>
            </tr>
            </thead>
            <tbody>
            {governingCouncil.map(({name, gender, dob, occupation, member}, index) => (
              <tr key={index} className="border-b-2 border-white">
                <td colSpan={1} className="text-left p-2">{index + 1}</td>
                <td colSpan={3} className="text-left p-2">{name}</td>
                <td colSpan={1} className="text-center p-2">{gender}</td>
                <td colSpan={3} className="text-center p-2">{occupation}</td>
                <td colSpan={2} className="text-center p-2">{member}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>

        <div className="h-20"/>
      </div>
    </div>
  );
}

export default GoverningCouncilSection;
